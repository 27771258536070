import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  CallToActionBanner,
  Content,
  ImageCollection,
  Reviews,
  InternalLink,
} from "@bluefin/components";
import { Header, Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Image
                images={[
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/logo_jpeg_7FODhD4.png",
                ]}
              />
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true} />
          <Content
            stackable={true}
            className={"component-section-container about-section"}
          >
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup truncate={true} paragraphs={3} width={12}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
              numToSelect: 2,
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={2}
          />
          <Grid
            stackable={true}
            className={"gallery-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <ImageCollection
                images={[
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image0.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image1.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image2.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image4.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image5.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image6.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image7.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image8.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image9.jpg",
                  "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/image10.jpg",
                ]}
                as={"carousel"}
                centered={true}
                cutUnevenRows={true}
                smartImageGrouping={false}
                disable={"none"}
                carouselOptions={{ autoScroll: true, autoScrollTime: 4000 }}
                header={""}
                numberOfRowDisplayItems={4}
              />
            </Grid.Column>
          </Grid>
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews.slice(0, 3)}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Customer Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <Button
              to={"/about/"}
              primary={true}
              size={"large"}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
